.operation_section_header {
    margin: 20px;
}

.operation_section {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 3px 10px 3px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.operation_list_table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
}

.operation_list_table_head th {
    background-color: #D9D9D9;
    padding: 10px;
    text-align: left;
    font-weight: 700;
    border: 1px solid #B4B4B4;
}

.operation_list_table_body td {
    padding: 10px;
    border: 1px solid #B4B4B4;
    font-weight: 600;
}

.operation_list_table_body td:last-child {
    text-transform: capitalize;
}

.priority-input {
    width: 50px;
    padding: 5px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.operation_list_status .pending {
    color: rgb(255, 55, 0);
}

.operation_list_status .on-going {
    color: orange;
}

/* Responsive styles */
@media (max-width: 768px) {
    .operation_list_table, .operation_list_table thead, .operation_list_table tbody, .operation_list_table th, .operation_list_table td, .operation_list_table tr {
        display: block;
    }

    .operation_list_table_head {
        display: none;
    }
.operation_list_table_body{
    
    border-bottom: 1px solid rgb(202, 200, 200);
}
    .operation_list_table_body td {
        padding: 10px;
        border: none;
        position: relative;
        padding-left: 50%;
        text-align: left;
    }

    .operation_list_table_body td::before {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        content: attr(data-label);
        font-weight: bold;
    }

    .priority-input {
        width: 100%;
        padding: 10px;
        margin-top: 5px;
    }
   
   
}
