.client_list_section{
    margin: 50px;
}

.client_list_header{
     padding: 10px;
}
.client_list_table{
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0px 0px 10px 0px #dcdcdc;
}

.client_list_table_head {
    border-radius: 0px 10px 10px 0px;
}

.client_list_table_head th {
    background-color: #1076AD !important;
    color: #fff;
    padding: 10px;
    text-align: left;
}

.client_list_table_head th:first-child {
    border-top-left-radius: 10px;
}

.client_list_table_head th:last-child {
    border-top-right-radius: 10px;
}

.client_list_table_body tr:nth-child(odd){
    background-color: #F1F7FA; 
    
}
.client_list_table_body tr:nth-child(even){
    background-color: #fff; 
}
.client_list_table_body tr td{
    padding: 10px;
}

.client_list_action img{
    width: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .client_list_table, 
    .client_list_table_head, 
    .client_list_table_body, 
    .client_list_table_head th, 
    .client_list_table_body td, 
    .client_list_table_body tr {
        display: block;
        width: 100%;
    }

    .client_list_table_head {
        float: left;
    }

    .client_list_table_body tr {
        margin-bottom: 15px;
    }

    .client_list_table_body tr td {
        display: block;
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .client_list_table_body tr td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 10px;
        font-weight: bold;
        text-align: left;
        color: #1076AD;
    }

    .client_list_table_head th {
        display: none;
    }
}