
.main-container-login{
    display: flex;
    align-items: center;
     gap: 150px;
}
.main-login-image{
    width: 100%;
    max-width: 800px;
  
}
.main-login-image img{
    width: 100%;
    height: 99.5vh;
    object-fit: cover;
}

.login-form {
   width: 400px;
   display: flex;
   flex-direction: column;
   gap: 20px;
  }
  
  .login-form-heading {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  
  .form-label-login {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .login-form-custom-input {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #C9BABA;
    background-color: #F8FAFB;
    box-sizing: border-box;
  }
  
  .form-remember-me {
    margin-left: 5px;
    color: #555;
    background-color: #F8FAFB;
  }
  
  .login-form-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: #1076AD;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  

  

  @media (max-width:1380px) {
    .main-container-login{
        gap: 30px;
    }
    .main-login-image img{
      width: 100%;
      object-fit:cover ;
  }
  .login-form-section{
    margin: 40px;
         padding: 10px;
}
  }
  @media (max-width:1100px) {
    .main-container-login{
        gap: 30px;
    }
    .main-login-image img{
      width: 96%;
      object-fit:fill ;
  }
  .login-form-section{
    margin: 40px;
         padding: 10px;
}
  }
  @media (max-width:768px) {
    .main-container-login{
        flex-direction: column;
        align-items: center;
        gap: 0px;
    }
    .main-login-image{
        width: 100%;
    }
    .main-login-image img{
        width: 100%;
        height: 45vh;
        object-fit: cover;
    }
    .login-form-section{
        margin: 10px;
             padding: 10px;
    }
    .login-form {
        width: 200px;
       }
  }