.production_pending_card{
    box-shadow: 0px 0px 10px 0px #dfdfdf;
    display:flex;
    justify-content: space-around;
    width: 500px;
    padding: 25px;
    margin: 20px;
    border-radius: 10px;
}

.production_pending_sub_card_1{
    background-color: #1076AD;
    padding: 10px;
    color: white;
    border-radius: 10px;
    width: 60%;
    text-align: left;
}

.production_pending_sub_card_2{
    background-color: #FAFAFA;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 35%;
}

.production_pending_card_section{
    display: flex;
    flex-wrap: wrap;
}