.first-dashboard-section {
  display: flex;
  width: 100%;
}

.second-dashboard-section {
  display: flex;

  width: 100%;

}



.Order-status-section {
  box-shadow: 0px 0px 10px 0px rgb(222, 222, 222);
  margin: 10px;
  border-radius: 10px;
  padding: 15px;
  background-color: #FAFAFA;
}

.total-clients {
  margin: 10px;
  box-shadow: 0px 0px 10px 0px rgb(222, 222, 222);
  margin: 10px;
  border-radius: 10px;
  padding: 15px;
  width: 35%;
  background-color: #FAFAFA;
}
@media (max-width:1110px) {
  .first-dashboard-section {
    flex-wrap: wrap;
  }

  .second-dashboard-section {
    flex-wrap: wrap;
  }
  .total-clients {
    width: 100%;
  }
}
@media (max-width:1312px) {
  .Order-status-section {
    margin: 8px;
  }
}

.total-clients-header-viewall {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #1176AD;
}

.total-clients-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.Clients-feedback {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.total-clients-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.total-clients-content img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.total-clients-text small {
  color: #6C757D;
}

.total-clients-footer {
  text-align: right;
  color: #1176AD;
}

.Bar-graph-1 {
  width: 370px;
  box-shadow: 0px 0px 10px 0px rgb(222, 222, 222);
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
  background-color: #FAFAFA;
}
@media (max-width:768px) {
  .Bar-graph-1{
    width: 290px;
  }
}

.Bar-graph-section {
  display: flex;
}

.completed-status {
  display: flex;
  align-items: center;
}

.completed-status-circle {
  width: 20px;
  /* Adjust the size as needed */
  height: 20px;
  /* Adjust the size as needed */
  background-color: #34B53A;
  border-radius: 50%;
  margin-right: 8px;
  /* Space between the circle and text */
}

.processing-status {
  display: flex;
  align-items: center;
}

.processing-status-circle {
  width: 20px;
  /* Adjust the size as needed */
  height: 20px;
  /* Adjust the size as needed */
  background-color: #4339F2;
  border-radius: 50%;
  margin-right: 8px;
  /* Space between the circle and text */
}

.pending-status {
  display: flex;
  align-items: center;
}

.pending-status-circle {
  width: 20px;
  /* Adjust the size as needed */
  height: 20px;
  /* Adjust the size as needed */
  background-color: #FFB200;
  border-radius: 50%;
  margin-right: 8px;
  /* Space between the circle and text */
}

.reschedule-status {
  display: flex;
  align-items: center;
}

.reschedule-status-circle {
  width: 20px;
  /* Adjust the size as needed */
  height: 20px;
  /* Adjust the size as needed */
  background-color: #FF3A29;
  border-radius: 50%;
  margin-right: 8px;
  /* Space between the circle and text */
}

.order-status-circles {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}

.order-status-big-circles {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.completed-status-big-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #bdf5c0;
  border: 10px solid #34B53A;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.processing-status-big-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #DAD7FE;
  border: 10px solid #4339F2;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pending-status-big-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fbe0a6;
  border: 10px solid #FFB200;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reschedule-status-big-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #FFE5D3;
  border: 10px solid #FF3A29;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.total-reports {
  width: 370px;
  box-shadow: 0px 0px 10px 0px rgb(222, 222, 222);
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
  background-color: #FAFAFA;
}

.progress-bar-section {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.total-reports-pending-bar-container {
  width: 100%;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.total-reports-pending-bar-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.total-reports-pending-bar-title {
  font-size: 16px;
  color: #555;
}

.total-reports-pending-bar-count {
  color: #ffa500;
  /* Adjust to the specific shade of orange if necessary */
}

.total-reports-pending-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.total-reports-pending-bar-fill {
  height: 100%;
  background-color: #ffa500;
  /* Adjust to the specific shade of orange if necessary */
  border-radius: 5px 0 0 5px;
}

.total-reports-production-bar-container {
  width: 100%;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.total-reports-production-bar-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.total-reports-production-bar-title {
  font-size: 16px;
  color: #555;
}

.total-reports-production-bar-count {
  color: #FA5A7D;
  /* Adjust to the specific shade of orange if necessary */
}

.total-reports-production-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.total-reports-production-bar-fill {
  height: 100%;
  background-color: #FA5A7D;
  /* Adjust to the specific shade of orange if necessary */
  border-radius: 5px 0 0 5px;
}

.total-reports-OperationPendency-bar-container {
  width: 100%;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.total-reports-OperationPendency-bar-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.total-reports-OperationPendency-bar-title {
  font-size: 16px;
  color: #555;
}

.total-reports-OperationPendency-bar-count {
  color: #BF83FF;
  /* Adjust to the specific shade of orange if necessary */
}

.total-reports-OperationPendency-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.total-reports-OperationPendency-bar-fill {
  height: 100%;
  background-color: #BF83FF;
  /* Adjust to the specific shade of orange if necessary */
  border-radius: 5px 0 0 5px;
}

.total-employees {
  margin: 10px;
  box-shadow: 0px 0px 10px 0px rgb(222, 222, 222);
  margin: 10px;
  border-radius: 10px;
  padding: 15px;
  width: 370px;
  background-color: #FAFAFA;
}

.total-employees-header-viewall {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #1176AD;
}

.total-employees-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.employees-feedback {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.total-employees-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.total-employees-content img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.total-employees-text small {
  color: #6C757D;
}

.total-employees-footer {
  text-align: right;
  color: #1176AD;
}