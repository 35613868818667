.modern-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #E8F2F7;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .search-container {
    display: flex;
    align-items: center;
    color: #969696;
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    width: 40%;

  }
  .search-icon{
    font-size: 20px;
  }
  .search-container input[type="search"] {
    border: none;
    outline: none;
    padding: 5px;
    margin-left: 5px;
    width: 100%;
  }
  
  .modern-header-icons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .modern-header-icons div {
    margin-right: 10px;
  }
  
  .profile-section {
    display: flex;
    align-items: center;
  }
  
  .profile-section img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }

.bell-icon{
    font-size: 20px;
    color: #1176AD;
}  


.Logout-section{
  font-size: 30px;
  cursor: pointer;
}


.notification-badge-container {
    position: relative;
    display: inline-block;
  }
  
  
  
  .notification-badge {
    position: absolute;
    top: -8px;
    right: 6px;
    background-color: #1176AD;
    color: white;
    font-size: 10px;
    padding: 0px 0px;
    border-radius: 50%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 15px;
    min-height: 15px;
   
  }