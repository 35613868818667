

.sales_order_list_section{
    margin: 50px;
}

.sales_order_list_create_btn{
    padding: 10px;
    margin: 10px;
    box-shadow: 0px 0px 10px 0px #d4effe;
    border-radius: 10px;
    width: 250px;
    cursor: pointer;
}
.sales_order_list_create_btn img{
    width: 20px;
}

.sales_order_list_create_btn button{
    border: none;
    background: none;
    color: #1076AD;
}