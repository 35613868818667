.custom-phone-input input {
    border: 1px solid #ced4da;
    border-radius: 4px;
    /* padding: 6px 12px; */
    width: 100%;
    font-size: 1rem;
}



/* Optional: If you want to style the country code dropdown as well */
.custom-phone-input .react-international-phone__dropdown {
    border: 1px solid #ced4da;
    border-radius: 4px;
}


.client_details{
    margin: 5px 50px;
    width: 100%;
    max-width: 1100px;
    box-shadow: 0px 0px 10px 0px rgb(227, 227, 227) ;
    padding: 30px;
    background-color: #FAFAFA;
    border-radius: 10px;
    display:flex ;
    flex-direction: column;
    gap: 20px;
}

.client_details_header{
    display:flex;
    justify-content: space-between;
}
.client_details_header_edit_view{
    display:flex;
    justify-content: space-between;
    margin: 15px 140px 0px 60px;
    align-items: center;
}
@media (max-width:768px) {
    .client_details_header_edit_view{
        flex-wrap: wrap;  
        margin: 7px 50px 0px 30px;
    }
}
.client_details_sub_header{
    margin: 10px 50px;
}
.client_details_save_btn {
    border: none;
    background-color: #1076AD;
    color: #ffff;
    padding: 8px 35px;
    border-radius: 7px;
} 

.client_details_form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (max-width:1390px) {
    .client_details{
        margin: 10px 15px;
        max-width: 880px;
    }
}
@media (max-width:768px) {
    .client_details_header{
    flex-direction: column;
    }

    .client_details{
        margin: 20px;
        width: 100%;
        max-width: 280px;
       
    }
}


