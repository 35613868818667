.App1 {
    display: flex;
    height: 100vh; 
  }
  
  /* .content {
    flex: 1; 
    overflow-y: auto; 
     max-width: 100%;
    z-index: 0; 
  } */

  .content {
    flex: 1;
    overflow-y: auto; 
    max-width: 100%; 
    margin: 0; 
    padding: 0; 
    box-sizing: border-box; 
    word-break: break-word; 
  }