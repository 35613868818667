.new_sales_order_header{
    margin: 20px;
}

.new_sales_order_sub_header{
    display: flex;
    justify-content: end;
    margin: 10px 30px;
}

.new_sales_order_form{
    margin: 10px 30px;
    background-color: #FAFAFA;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #ededed ;
}
.new_sales_order_form_main{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.gst_card_1 {
    background-color: white;
    box-shadow: 0px 0px 10px 0px #dddbdb;
    width: 280px;
    padding: 15px 30px;
    border-radius: 10px;
    margin: 10px;
  }
  
  .gst_card_header {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .gst_sub_card_1 {
    border: 1px solid rgb(193, 191, 191);
    border-radius: 10px;
    display: flex;
    align-items: center; /* Ensure vertical alignment */
    width: 110px;
  }
  
  .gst_rate {
    background-color: #1076AD;
    padding: 10px;
    color: white;
    border-radius: 10px 0px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  .gst_percent_input {
    padding: 10px;
    border: none;
    outline: none;
    width: 50px; /* Adjust the width as needed */
    text-align: center;
    border-radius: 0px 10px 10px 0px;
  }
  
  .gst_percent_input:focus {
    outline: none;
  }
  
  .gst_rate_rs_section {
    display: flex;
    gap: 10px;
  }
  