.view_sales_order_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px;
}

.view_sales_order_sub_header{
    display: flex;
    align-items: center;
    gap: 20px;
}

.view_sales_order_btn img{
    width: 20px;
    height: 20px;
}

.view_sales_order_btn button{
    border: none;
    background-color: transparent;
    color: #1076AD;
}
.view_sales_order_btn{
    box-shadow: 0px 0px 10px 0px #d2eefd;
    border-radius: 10px;
    padding: 10px;
}


.view_sales_order_tab_bar{
    display: flex;
    justify-content: space-between;
    margin: 0px 50px;
    background-color: #D9D9D9;
    border-radius: 10px;
 
}

.view_sales_order_tab_bar div{
    text-align: center;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
  
}

.view_sales_order_tab_bar .tab_bar_active{
    background-color: #1076AD;
    color: white;
}


.view_sales_order_details{
    padding-top: 20px;
    margin: 10px;
}