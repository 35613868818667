/* Sidebar.css */
.sidebar {
    width: 250px;
    background-color: #E8F2F7;
    color: #333;
    padding: 10px;
    transition: width 0.3s ease;
    position: relative; 
    height: 100vh; 
    box-shadow: 0px 0px 10px 0px rgb(211, 211, 211);
    top: 0; 
    left: 0;
    z-index: 1000; 
}
.sidebar.closed {
    width: 70px;
    height: 100vh; 
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; 
}

.toggle-btn {
    right: 0;
    position: absolute;
    background: none;
    color: #000;
    border: none;
    padding: 10px;
    cursor: pointer;
    float: right;
    z-index: 0;
}

.content-sidebar1 {
    display: flex;
    flex-direction: column;
    /* overflow-y: auto; */
    height: calc(100vh - 60px); 
}

.sidebar-logo-bhama {
  text-align: center;
      margin: -5px 0px;
}

.sidebar-logo-bhama img {
    width: 100px;
    text-align: center;
}
.content-sidebar {
    display: flex;
    flex-direction: column;
    overflow-y: auto; 
    height: 100%;
    gap: 10px;
}
.sidebar.closed .content-sidebar {
    display: flex;
    flex-direction: column; 
    height: 100%;
    gap: 10px;
}

.content-sidebar::-webkit-scrollbar {
    width: 2px; 
}

.content-sidebar::-webkit-scrollbar-track {
    background: #f1f1f1; 
}


.content-sidebar::-webkit-scrollbar-thumb {
    background: #cecdcd; 
}


.content-sidebar::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
.ul-sidebar {
    padding: 0;
    width: 100%;
}

.ul-sidebar .li-sidebar {
    display: flex;
    align-items: center;
    font-size: 20px;
    gap: 10px;
    padding: 10px;
    width: 100%;
    max-width: 220px;
    margin: 13px 3px;
    box-shadow: 0px 0px 10px 0px rgb(235, 234, 234);
    background-color: #FFFFFF;
    border-radius: 10px;
}
.ul-sidebar .li-sidebar-min {
    display: flex;
    align-items: center;
    font-size: 20px;
    gap: 10px;
    padding: 10px;
    width: 100%;
    max-width: 45px;
    margin: 13px 3px;
    box-shadow: 0px 0px 10px 0px rgb(235, 234, 234);
    background-color: #FFFFFF;
    border-radius: 10px;
}

.ul-sidebar .li-sidebar a {
    color: #666666;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 17px;
}
.ul-sidebar .li-sidebar a img{
    width: 17px;
}
.ul-sidebar .li-sidebar-min a img{
    width: 20px;
}
.ul-sidebar .li-sidebar-min a img{
    width: 20px;
}
.ul-sidebar .li-sidebar:active,
.ul-sidebar .li-sidebar.active 
{
    background-color: #1076AD;
    color: white; 
}
.ul-sidebar .li-sidebar:active,
.ul-sidebar .li-sidebar-min.active 
{
    background-color: #1076AD;
    color: white; 
}
.ul-sidebar .li-sidebar:active,
.ul-sidebar .li-sidebar.active a
{
    background-color: #1076AD;
    color: white; 
}

.closed-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 50px; 
}

.dropdown-main {
    position: relative;
    cursor: pointer;
    color: #666666;
}
.dropdown-btn{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 17px;
}
.dropdown-btn img{
    width: 17px;
}

.dropdown-main-menu li{
    padding: 5px;
    list-style: disc;
    
}
.dropdown-main-menu li::marker{
   color: #666666;
}

.sidebar-dropdown-links{
    text-decoration: none;
    color: #666666;
}

.sidebar-dropdown-links:active,
.sidebar-dropdown-links.active ,.dropdown-main-menu li.active::marker{
    color: #1076AD;
}


.dropdown-min {
    position: relative;
}

.dropdown-min .dropdown-btn-min {
    cursor: pointer;
    display: block;
}
.dropdown-btn-min div{
    display: flex;
    align-items: center;
    gap: 10px;
}
.dropdown-btn-min div img{
 width: 22px;
}
.dropdown-min .dropdown-content-min {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 160px;
    z-index: 1000;
    border-radius: 10px;
}

.dropdown-min .dropdown-content-min a {
    display: block;
    padding: 10px 20px;
    color: #333;
    white-space: nowrap; /* Prevent text wrapping */
}

.dropdown-min .dropdown-content-min a:hover {
    background-color: #f2f2f2;
}

.dropdown-min:hover .dropdown-content-min {
    display: block;
    max-height: none; /* Ensure dropdown content fits within available space */
    overflow-y: hidden; /* Hide vertical scrollbar */
}

.side-bar-img-min img{
    width: 80px;
}