.remark_input{
    width: 80px;
    padding: 5px;
    text-align: center;
    border: none;
    border-radius: 5px;
}


.remark_input:focus{
    outline: none;
}

.sales_order_pendency_dropdown_status{
    border: none;
    padding: 5px;
}

.sales_order_pendency_status_open {
    background-color: green;
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
}

.sales_order_pendency_status_close {
    background-color: #dc4c4c;
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
}

.sales_order_pendency_edit_button img{
    width: 20px;
    border: none;
    background: none;
}

.sales_order_pendency_save_btn button{
    background-color: #1076AD;
    border: none;
    color: white;
    padding: 8px 40px;
    border-radius: 5px;
}

.sales_order_pendency_section_header{
    display:flex;
    margin: 20px 70px;
    justify-content: space-between;
}

.sales_order_pendency_date_section{
    display: flex;
    align-items: center;
    gap: 30px;
}
.sales_order_pendency_date {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px; /* Adjust margin as needed */
    padding: 10px;
    box-shadow: 0px 0px 10px 0px #bee3f6;
    border-radius: 10px;
    color: #1076AD;

}

.sales_order_pendency_date input{
    width: 21px;
    outline: none;
    border: none;
    color: #1076AD;
}


.sales_order_pendency_total_section{
    margin: 40px;
    display: flex;
    align-items: center;
    gap: 20px;

}

.sales_order_pendency_total_dispatch{
    background-color: #E8F2F7;
    padding: 10px  30px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 20px;
    box-shadow: 0px 0px 10px 0px #d5d7d7;
    border: 1px solid #BFBFBF;
}