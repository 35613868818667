.operation_master_header{
    display:flex;
    justify-content: space-between;
    margin: 15px 60px;
}

.operation_master_label{
    font-weight: 600;
}

.operation_master_btn{
    background-color: #1076AD;
    padding: 10px 40px;
    border: none;
    border-radius: 10px;
    color: white;
}

.operation_master_table_div{
    box-shadow: 0px 0px 10px 0px #dfdfdf;
    padding: 40px 60px 40px 20px; 
    border-radius: 10px;
   
}